.about-content{
    /* font-size: 50px; */
    padding: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.About-banner{
    background-image:url("/public/AboutImages/about-us-banner.png");
    width: 100%;
    height: 600px;
    align-items: center;
    justify-content: center;
    display: flex;
}
.About-banner h1 {
    font-weight: 700;
    color: #333;
    font-size: 52px;
    font-family: Visby CF,Arial,sans-serif;
    
}
.about-content-section{
    padding: 30px 0px;
}
.about-content-section ul li{
    font-size: 16px;
    font-weight: 400;
    color: black;
    margin-bottom: 20px;
}
.about-content h1 {
    font-size: 32px;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    border-bottom: 2px solid rgb(123, 122, 122);
}
.branches-about-card{
    background-color: #fff;
    border: 2px solid #e4b377;
    border-radius: 16px;
    padding: 10px;
    transition: transform .2s;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 0px 2px 0px #f7a643
    

}

.branches-about-card img{
    height: 300px;
    width: 100%;

}
.brances-about-card-content-heading{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
}
.brances-about-card-content-heading h6 {
    font-weight: bold;
    font-size: 25px;

}
.brances-about-card-content-heading  svg {
    font-size: 22px;
}

.branches-about-card-content p {
    font-size: 18px;
    color: rgb(117, 115, 115);
    font-weight: 700;
}
.about-Our-section{
    margin: 40px 0px;

}
.about-advisor-section{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.about-advisor-section p{
font-size: 16px;
}
.about-advisor-section svg {
    font-size: 18px;
}
.branches-about-bottom-content{
    position: relative;
    top: 0px;
    left: 20px;
    z-index: 1;
    width: 400px;
}
.branches-about-bottom-content h3{
      font-size: 40px;
      font-weight: 700;
      color:black;
}
.branches-about-investor-heading{
    align-items: center;
    display: flex;
    justify-content: center;
}
.branches-about-investor-heading h6 {
    font-size: 32px;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    /* width:200px; */
    border-bottom: 2px solid rgb(123, 122, 122);
}
.branches-about-design{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.branches-about-design {
    margin-top: 30px;
}
.branches-about-inverstor-section{
    width: 300px;
}
.branches-about-inverstor-section img{
    height: 50px;
    width: 300px;
}
.about_content {
    padding:8% 5%
}

.about_content .about_left_sec a{
 color:white;
 text-decoration: none;
 border-radius: 8px;
 background-color: #f7a643;
 padding:10px 40px;
 font-size:14px;
 font-weight: bold;
}
.about_content .about_left_sec h2 {
    font-weight: bold;
    font-size:40px;
    margin:20px 0px;
}
.about_content .about_left_sec p{
    font-size:18px;
    opacity: 0.7;
    color:black;
    text-align: justify;
}
.about_content .about_left_sec h2 span{
    color:#f7a643
}
.about_content img {
    width:100%
}




@media screen and (max-width:1440px){
    .branches-about-bottom-content {
        width: 470px;
    }
}
@media (min-width:700px) and (max-width:1024px){
    .branches-about-inverstor-section img{
        margin-top: 40px;
    }
    .branches-about-bottom-content h3{
        font-size: 28px;
    }
    .About-banner h1 {
        font-size: 25px !important;
    }
    .about_content .about_left_sec h2{
        font-size: 22px !important;
        margin-top: 50px;
    }
    .about_content .about_left_sec p{
        font-size: 16px !important;
    }
}
@media only screen and (max-width:700px){
    .branches-about-bottom-content{
        width: 100%;
        left:10px
    }
    .branches-about-bottom-content h3{
        font-size: 17px;
    }
    .branches-about-inverstor-section{
        margin: 20px 0px;
    }
    .About-banner{
        height: 300px;
        background-size: contain;
    }
    .About-banner h1 {
        width: 100%;
        text-align: center;
        font-size: 25px;
    }
    .about-content h1{
        font-size: 25px;
    }
    .about_content .about_left_sec h2{
        font-size: 28px;
    }
}