.branches-model-demo{
    height: 600px;
    width: 800px;
}
.branches-model-demo-right-icons{
display: flex;
justify-content: end;
margin-top: 20px;
font-size: 22px;
}
.branches-model-demo-right-section{
    padding:20px;
}
.branches-model-demo-main{
    height: 600px;
    width: 100%;
}
.branches-model-demo-left{
    height: 597px;
    width:100%
}
.branches-model-demo-left img{
    height: 100%;
    width: 100%;
    border-radius: 25px;
}
.brnaches-model-from-heading h6{
    font-size: 22px;
    font-weight: 700;
    color:black
}
.brnaches-model-from-heading p {
    font-size: 16px;
    font-weight: 400;
    color:#8f8989
}
.branches-form-input-field label{
    color:rgb(127, 124, 124)
}
.branches-form-input-field input.form-control:focus {
outline: none;
box-shadow: none;
border-color: #727070;
}
@media only screen and (max-width:950px){
    .branches-model-demo{
        height: 600px;
        width: 600px;
    }
}
@media only screen and (max-width:767px){
    .branches-model-demo{
        width:300px
    }
}