body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root{
  --bg-color:#F7941E;
  --white:#ffffff;
  --black:#333
  /* --black:#dcdcdc */
}

h1{
  font-family: Visby CF,Arial,sans-serif !important;
  color: #333 !important;
}
h2{
  font-family: Visby CF,Arial,sans-serif !important;
  color: #333 !important;
}
h3{
  font-family: Visby CF,Arial,sans-serif !important;
  color: #333 !important;
}
h4{
  font-family: Visby CF,Arial,sans-serif !important;
  color: #333 !important;
}
h5{
  font-family: Visby CF,Arial,sans-serif !important;
  color: #333 !important;
}
h6{
  font-family: Visby CF,Arial,sans-serif !important;
  color: #333 !important;
}
.branchesglobalnetworks_global_button{
  /* background-color: var(--bg-color); */
  background-image: linear-gradient(to bottom, var(--bg-color),#f7a643);
  color: var(--white);
  padding: 8px 30px;
  border-radius: 7px;
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  transition: .8s;
}
.branchesglobalnetworks_global_button:hover{
  background-image: linear-gradient(to bottom,#eba755,  var(--bg-color));
  scale: 1.05;
}


@media (min-width:1040px) and (max-width:1440px){


}