/* .DropShipOptimazation{
    padding: 5% 0%;
  }
  
  .DropShipOptimazation .service-optimazation-section {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
  }
  .DropShipOptimazation  .service-optimazation-heading h2 {
    font-weight: 700;
    text-align: center;
    font-size: 32px;
  }
  .DropShipOptimazation  .service-optimazation-content {
    padding: 0px 20px;
    text-align: center;
  }
  .DropShipOptimazation .service-optimazation-content img {
  max-width: 100%;
  }
  .DropShipOptimazation  .service-optimazation-content h6 {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-top: 20px;
  }
  .DropShipOptimazation .service-optimazation-content p {
    font-size: 16px;
    color: #707070;
    padding: 10px;
    text-align: justify;
  }

  
  @media only screen and (max-width: 900px) {
    .DropShipOptimazation  .service-optimazation-content h6 {
      font-size: 25px;
      padding: 0px 10px;
    }
    .DropShipOptimazation  .service-optimazation-content p {
      font-size: 14px;
      font-weight: 500;
      color: #707070;
      padding: 5px;
      text-align: center;
    }
    .DropShipOptimazation .service-optimazation-content img {
      height: 200px;
    }
  }
  @media only screen and (max-width: 550px) {
    .DropShipOptimazation   .service-optimazation-heading h2 {
      font-weight: 700;
      text-align: center;
      font-size: 25px;
    }
    .DropShipOptimazation   .service-optimazation-content h6 {
      font-size: 25px;
    }
    .DropShipOptimazation  .service-optimazation-content p {
      font-size: 15px;
    }
  }
  @media only screen and (max-width: 1401px) {
   .whitegloveOptimazation  .service-optimazation-content img {
      width: 230px;
      height: 145px;
    }
   .whitegloveOptimazation   .service-optimazation-content h6 {
      font-size: 20px;
    }
  }
  @media only screen and (max-width: 1025px) {
   .whitegloveOptimazation  .service-optimazation-content img {
      width: 205px;
      height: 130px;
    }
   .whitegloveOptimazation  .service-optimazation-content h6 {
      font-size: 20px;
    }
  }
   */

.stack_bed_main {
  padding: 5% 0%;
}
.stack_bed_main_first {
  position: sticky;
  top: 15%;
}

.stack_bed_main_first h2 {
  font-size: 45px;
  color: #333;
  font-weight: 600;
}
.stack_bed_main_first h6 {
  font-size: 20px;
  color: #333;
  line-height: 30px;
}
.stack_bed_main_second_box img {
  width: 60px;
  height: 60px;
  margin-bottom: 20px;
}
.stack_bed_main_second_box {
  width: 100%;
  height: 100%;
  padding: 1.5em;
  background: linear-gradient(#ffffff 50%, #f6941e 50%);
  background-size: 100% 200%;
  border-radius: 5px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: 0.8s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px;
}

.stack_bed_main_second_box:hover {
  background-position: 0 100%;
}
.stack_bed_main_second_box:hover h2 {
  color: white !important;
}
.stack_bed_main_second_box:hover img {
  display: none !important;
}
.stack_bed_main_second_box:hover p {
  color: white;
}

.stack_bed_main_second_box h2 {
  color: #333;
  font-weight: 600;
  font-size: 24px;
}

.stack_bed_main_second_box p {
  text-align: justify;
  padding: 0px;
  font-size: 18px;
  line-height: 25px;
}

.stack_bed_control_center_main h2 {
  font-size: 38px !important;
  color: #333 !important;
  font-weight: 700;
  font-family: Visby CF, Arial, sans-serif !important;
}
.stack_bed_control_center_main p {
  font-size: 20px;
  color: #333;
  font-family: Visby CF, Arial, sans-serif;
}

.stack_bed_control_center_main img {
  max-width: 100%;
}

.stack_bed_driver_app_main h2 {
  font-size: 38px !important;
  color: #333 !important;
  font-weight: 700;
  font-family: Visby CF, Arial, sans-serif !important;
}
.stack_bed_driver_app_main p {
  font-size: 20px;
  color: #333;
  font-family: Visby CF, Arial, sans-serif;
}

.stack_bed_driver_app_main img {
  max-width: 100%;
}

.stake_bed_relative_box {
  position: relative !important;
  top: 5% !important;
}

@media (min-width: 600px) and (max-width: 1024px){
  .stack_bed_main_first h2 {
    font-size: 28px !important;
  }
  .stack_bed_main_first h6{
    font-size: 18px !important;
  }
  .stack_bed_main_second_box h2{
    font-size: 18px !important;
  }
  .stack_bed_main_second_box p{
    font-size: 16px;
  }
  .stack_bed_control_center_main h2 {
    font-size: 28px !important;
    margin-top: 20px;
  }
  .stack_bed_driver_app_main h2 {
    font-size: 28px !important;
  }
  .stack_bed_driver_app_main p{
    font-size: 18px !important;
  }
}

@media only screen and (max-width: 600px) {
  .stack_bed_main_first h2 {
    font-size: 28px;
  }
  .stack_bed_main_first h6 {
    font-size: 16px;
  }
  .stack_bed_main_first {
    position: relative;
    top: 0;
  }
  .stack_bed_control_center_main h2 {
    font-size: 28px !important;
    margin-top: 20px;
  }
  .stack_bed_control_center_main p {
    font-size: 16px;
  }
  .stake_bed_last_section_mobile_responsive {
    flex-direction: column-reverse;
  }
  .stack_bed_driver_app_main h2 {
    font-size: 28px !important;
    margin-top: 70px;
  }
  .stack_bed_driver_app_main p {
    font-size: 16px !important;
  }
}
