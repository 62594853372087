.branches-privacy-section-main{
    padding: 20px 0px;
}
.privacy-banner{
    background-image:url("/public/serviceImages/servicebanner.jpg");
    width: 100%;
    height: 300px;
    /* /Users/kamleshburi/branchesglobalnetworks/public/AboutImages/contactus.jpg */
}
.privacy-banner h1 {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 100%;
}
.branches-terms-heading h4{
    font-size: 22px;
    font-weight: 700;
}
.branches-terms-content-section-heading h6{
font-size: 22px;
font-weight: 500;

}
.branches-terms-content-section-heading p{
    font-size: 16px;
    font-weight: 400;
    
    }
