.branches-career-main{
    background-image: url("../.././../public//CarriersImages/Carriersbanner.jpg");
    height:600px;

    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 8px;
    margin-top: 0.5rem;
    margin-left: 1.6rem;
    margin-right: 1.6rem;
    position: relative;
}
.branches-career-main-box{
    /* display: flex;
     */
     padding: 30px;
     border-radius: 16px;
     justify-content: space-between;
     width: 90%;
     max-width: 140.3rem;
     margin-left: auto;
     margin-right: auto;
     display: flex;
     position: absolute;
     top: auto;
     bottom: -5.8rem;
     left: 0%;
     right: 0%;
     box-shadow: inset 2px 2px 8px rgba(255, 255, 255, .75), 1px 24px 36px rgba(131, 192, 229, .15);
     background-color: white;
     align-items: center;
}
.branches-career-banner-left-side{
    width: 40%;
}
.branches-career-banner-left-side h1 {
    font-size: 40px;
    font-weight: bold;
    padding:10px 20px;
    width:80%;
    /* background-image: linear-gradient(54deg, #55c3ff, #55c3ff 25%, #1ef5b9); */
    /* -webkit-text-fill-color: transparent; */
    /* color:rgb(92, 131, 131) */
}
.branches-career-banner-right-side{
    padding: 10px 30px;
    /* width: 00px; */
    width: 60%;

}
.branches-career-banner-right-side p{
    font-size: 25px;
    font-weight: 500;
    color: rgb(150, 147, 147);


}
.branches-career-banner-right-side a {
    text-decoration: none;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    color:#f7a643
}
.branches-career-banner-right-side a svg {
    font-size: 22px;
}
@media only screen and (max-width:1200px){
    .branches-career-banner-left-side h1 {
        font-size: 30px;
        padding: 10px 10px;
        width:90%
    }
    .branches-career-banner-right-side p{
        font-size: 18px;
    }
}
@media only screen and (max-width:1020px){
    .branches-career-main-box {
        padding: 20px;
    }
    .branches-career-banner-left-side h1 {
        font-size: 30px;
        padding: 10px 10px;
        width:90%
    }
    .branches-career-banner-right-side p{
        font-size: 18px;
    }
    .branches-career-banner-right-side a {
        font-size: 15px;
    }
}
@media only screen and (max-width:767px){

.branches-career-main{
    height: 300px;
}
    .branches-career-main-box {
        flex-wrap: wrap;
        bottom:-10.8rem
    }
    
    .branches-career-banner-left-side {
        width: 100%;
    }
    .branches-career-banner-right-side{
        width:100%;
        padding: 10px 15px;
    }
    .branches-career-banner-left-side h1 {
        width:100%;
        font-size: 18px;
    }
    .branches-career-banner-right-side p{
        font-size: 13px;

    }
}