.FulfillmentSlider .FulfillmentSlider_Arrowr_right {
  display: block;
  color: black;
  position: absolute;
  bottom: -10px;
  left: 10%;
  width: 50px;
  height: 50px;
  background-color: #014698;
  border: 1px solid #134a80;
  padding: 8px;
  color: #ffffff;
  cursor: pointer;
  z-index: 99;
  font-size:10px
}
.FulfillmentSlider {
  padding: 5%;
}
.FulfillmentSlider h2{
  font-size: 45px;
  color: #333;
  font-weight: 600;
  margin-bottom: 20px;
}

.FulfillmentSlider .FulfillmentSlider_Arrowr_left {
  color: black;
  position: absolute;
  z-index: 99;
  bottom: -10px;
  right: 91%;
  width: 50px;
  height: 50px;
  background-color: #014698;
  border: 1px solid #134a80;
  padding: 8px;
  color: #ffffff;
  cursor: pointer;
}

.FulfillmentSlider img {
  width: 100%;
  border-radius: 15px;
  border: 0.5px solid #ededed;
}
.FulfillmentSlider p{
    text-align: justify;
    line-height: 2rem;
    font-size: 18px;
    color: #333;
}

.Fulfillmentextended a{
  color:#fabb00;
  font-size:20px; 
  font-weight: bold;
}


@media only screen and (max-width: 1440px){
  .FulfillmentSlider img {
    max-width: 100%;
  }
}
@media (min-width:600px) and (max-width:1024px){
  .FulfillmentSlider .FulfillmentSlider_Arrowr_right{
    width: 40px;
    height: 40px;
  }
  .FulfillmentSlider .FulfillmentSlider_Arrowr_left{
    width: 40px;
    height: 40px;
  }
  .FulfillmentSlider h2{
    font-size: 28px;
  }
  .FulfillmentSlider p{
    line-height: 1.5rem;
  font-size: 16px;
  }
}
@media (min-width:280px) and (max-width:600px){
  .FulfillmentSlider .FulfillmentSlider_Arrowr_left{
    width: 40px;
    height: 40px;
  }
  .FulfillmentSlider .FulfillmentSlider_Arrowr_right{
    width: 40px;
    height: 40px;
  }
  .FulfillmentSlider h2{
    font-size: 28px;
  }
  .FulfillmentSlider p{
    line-height: 1.5rem;
  font-size: 16px;
  }
}
