.lastMileOptimazation{
    padding: 5% 0%;
  }
  
  .lastMileOptimazation .service-optimazation-section {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
  }
  .lastMileOptimazation  .service-optimazation-heading h2 {
    font-weight: 700;
    text-align: center;
    font-size: 38px;
    /* text-align: center; */
    /* justify-content: center; */
  }
  .lastMileOptimazation  .service-optimazation-content {
    padding: 0px 20px;
    text-align: center;
    /* flex-wrap: wrap; */
  }
  .lastMileOptimazation .service-optimazation-content img {
    width: 100%;
    padding: 20px;
  }
  .lastMileOptimazation  .service-optimazation-content h6 {
    font-size: 26px;
    font-weight: bold;
    text-align: center;
  }
  .lastMileOptimazation .service-optimazation-content p {
    font-size: 16px;
    /* font-weight: 500; */
    color: #707070;
    padding: 10px;
    text-align: justify;
  }
  /* @media (min-width: 768px) and (max-width: 1100px) {
    .service-optimazation-content h6 {
      min-height: 120px;
    }
  } */
  @media screen and (max-width:1440px){
    .lastMileOptimazation .service-optimazation-content h6 {
      font-size:22px
    }
    .lastMileOptimazation .service-optimazation-heading h2 {
      font-size:28px;
    }
  }
  @media only screen and (max-width: 900px) {
    .lastMileOptimazation  .service-optimazation-content h6 {
      font-size: 25px;
      padding: 0px 10px;
      /* min-height: 100px; */
    }
    .lastMileOptimazation  .service-optimazation-content p {
      font-size: 14px;
      font-weight: 500;
      color: #707070;
      padding: 5px;
      text-align: center;
    }
    .lastMileOptimazation .service-optimazation-content img {
 
    }
  }
  @media only screen and (max-width: 550px) {
    .lastMileOptimazation   .service-optimazation-heading h2 {
      font-weight: 700;
      text-align: center;
      font-size: 25px;
      /* text-align: center; */
      /* justify-content: center; */
    }
    .lastMileOptimazation   .service-optimazation-content h6 {
      font-size: 25px;
    }
    .lastMileOptimazation  .service-optimazation-content p {
      font-size: 15px;
    }
  }
  @media only screen and (max-width: 1440px) {
   .whitegloveOptimazation  .service-optimazation-content img {
      width: 100%;
    }
   .whitegloveOptimazation   .service-optimazation-content h6 {
      font-size: 20px;
    }
  }
  @media only screen and (max-width: 1025px) {
   .whitegloveOptimazation  .service-optimazation-content img {
      width: 205px;
      height: 130px;
    }
   .whitegloveOptimazation  .service-optimazation-content h6 {
      font-size: 20px;
    }
  }
  