.branches-careerOur-value-main{
    padding: 200px 0px 20px 0px;
}
.branches-career-our-value-heading{
    display: flex;
    align-items: center;
    justify-content: center;
}
.branches-career-our-value-heading h1 {
    border-bottom: 2px solid black;
    font-size: 32px;
    font-weight: 700;
}
.branches-career-our-value-content{
    margin: 50px 0px;
}
.branches-career-our-card{
    /* border: 1px solid black; */
    /* height: 300px; */
    width: 100%;
    padding: 10px 20px;
    /* box-shadow: inset 0px 0px 10px 0px rgb(132, 129, 129); */
}
.branches-career-our-card img {
    height: 200px;
    width:100%
}
.branches-career-our-card h1{
    font-size: 32px;
    font-weight: bold;
text-align: center;
margin-top: 20px;
}
.branches-career-our-card p {
    text-align: center;
    justify-content: center;
    font-size: 22px;
    font-weight: 500;
    color: rgb(132, 129, 129);
}
@media only screen and (max-width:767px){
    .branches-career-our-card h1{
        font-size: 25px;
    }
    .branches-career-our-value-heading h1{
        font-size: 25px;
    }
}