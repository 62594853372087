
.blog_details_banner img{
 width: 100%;
}
.blog_details_banner_text{
    font-weight: 700;
    color: #333;
    position: absolute;
    top: 40%;
    left: 4%;
}

.blog_details_banner_text h1{
    font-weight: 700;
    color: #333;
}

.blog_details_banner_text h4{
    color: #333;
    width: 80%;
}

.blog_details_des{
    padding: 2% 5%;
}
.blog_details_des h2{
    font-weight: 700;
}

@media only screen and (max-width: 750px){
    .blog_details_banner_text{
        top: 20% !important;
        display: none !important;
    }
    .blog_details_banner_text h4{
        color: #333;
        width: 95%;
        font-size: 18px !important;
    }
    .blog_details_banner_text h1{
        font-size: 22px !important;
    }
}