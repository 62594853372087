.Fulfillmentextended{
  padding:5% 0%;

}

.Fulfillmentextended .content {
width:80%;
background: linear-gradient(315deg, transparent 80px, #084997 80px);
padding:4% 6%;
}

.Fulfillmentextended .content  h2{
    color:white !important;
    font-size:42px;
}
.Fulfillmentextended .content  p{
    font-size:18px;
    color:white;
    margin-top:10px;
    line-height: 30px;
}
@media (min-width:280px) and (max-width:600px){
  .Fulfillmentextended .content {
    width: 100%;
  }
  .Fulfillmentextended .content h2{
    font-size: 28px;
  }
  .Fulfillmentextended .content p{
    line-height: 25px;
    font-size: 16px;
    text-align: justify;
  }
}

@media (min-width:600px) and (max-width:1024px){
  .Fulfillmentextended .content {
    width: 100%;
  }
  .Fulfillmentextended .content h2{
    font-size: 28px;
  }
  .Fulfillmentextended .content p{
    line-height: 25px;
    font-size: 16px;
    text-align: justify;
  }
}