.branches_home_service_main h2 {
  font-weight: 700;
  text-align: center;
  font-size: 38px;
  color: #333;
}

.branches_home_service_main {
  padding: 3% 0% 0% 0px;
  /* background-color: #f9f7f2; */
}

.branches_home_service_line_heading {
  height: 2px;
  background-color: var(--black);
  width: 200px;
  margin: auto;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.5), 0 0 25px #657684;
}
.branches_home_service_main ul li {
  width: 20%;
  padding: 20px;
  list-style: none;
  cursor: pointer;
  transition: 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
}




.branches_home_service_main ul li video {
  display: none;
  transition: 0.2s;
}

.branches_home_service_main ul li:hover img {
  display: none;
}

.branches_home_service_main ul li:hover video {
  display: block;
  transition: 0.2s;
  padding: 10px 0px;
}

.branches_home_service_main ul li:hover p {
  font-size: 1.3rem;
  color: var(--bg-color);
  
}

.branches_home_service_main ul li p {
  font-size: 1.3rem;
  font-weight: 700;
  color: rgb(51, 50, 50);
  margin: 0px;
  padding: 0px;
  text-align: center;
  transition: 0.2s;
}

.branches_home_service_main ul li img {
  width: 45%;
 padding: 45px 0px;
}
@media only screen and (max-width: 1020px) {
  .branches_home_service_main ul li p {
    font-size: 16px;
  }
  /* .branches_home_service_main ul li img {
    padding: 29px;
  } */
  .branches_home_service_main h2 {
    font-size: 24px;
  }
  .branches_home_service_line_heading {
    width: 154px;
  }
}
@media only screen and (max-width: 767px) {
  .branches_home_service_main ul li {
    width: 100%;
  }
  /* .branches_home_service_main ul li img {
    padding: 50px;
  } */
  .branches_home_service_main ul li p {
    font-size: 22px;
  }
}

@media only screen and (max-width: 992px) {
  .branches_home_service_main ul {
    /* width: 100%; */
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 800px) {
  .branches_home_service_main ul li img {
    width: 50%;
  }
  .branches_home_service_main ul li{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}


@media only screen and (max-width: 767px){
  .branches_home_service_main ul li img {
    padding: 15px 0px !important;
  }

}