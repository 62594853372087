.footer {
  background-color: #1c1c1c;
  padding: 50px 0px 0px 0px;
}

.footer-first {
  color: #fff;
  color: #fff;
  display: flex;
  flex-direction: column;
}

.footer-first p {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
  text-align: justify;
  padding: 0px 110px 0px 0px;
}
.footer-first img {
  /* width: 73%;
  height:90px;
  font-weight: 400;
  font-size: 80px; */
  width: 220px;
}
.branches_footer_social_media-icon {
  display: flex;
  justify-content: space-between;
  width: 200px;
}
.branches_footer_social_media-icon a {
  color: #fff;
}

.branches_footer_social_media-icon svg {
  border: 1.5px solid #ffffff;
  border-radius: 50%;
  font-size: 25px;
  width: 40px;
  height: 40px;
  padding: 10px;
  cursor: pointer;
}
.footer-center {
  color: #fff;
  display: flex;
  flex-direction: column;
}
.footer-center h3 {
  font-weight: 700;
  font-size: 20px;
  line-height: 48px;
  text-transform: uppercase;
  color: #ffffff !important;
  text-align: start;
}

.footer-center h2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 48px;
  text-transform: uppercase;
  color: #ffffff !important;
  text-align: start;
}

.footer-center ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0px;
}

.footer-center ul li {
  list-style: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 33px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.footer-center ul li:hover {
  text-decoration: underline;
}

.footer-center p {
  font-weight: 400;
  font-size: 16px;
  padding: 0px;
  margin: 0px;
  line-height: 24px;
  width: 100%;
}
.footer-center span {
  text-align: justify;
  font-weight: 400;
  font-size: 16px;
  line-height: 45px;
  padding: 0px;
  margin: 0px;
  width: 30px;
}

.footer-email {
  /* width: 300px; */
}

.footer-bottom-first {
  display: flex;
  justify-content: space-between !important;
  color: #fff;
  width: 100%;
}
.footer-bottom-first p {
  padding: 10px 0px;
  margin: 0px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
}
.copyright {
  color: #fff;
  padding: 0px;
  margin: 0px;
  font-size: 14px;
}
@media (min-width: 800px) and (max-width: 1200px) {
  .footer-first p {
    padding: 0px 20px 0px 0px;
  }
}
@media (min-width: 1200px) and (max-width: 1440px) {
  .footer-first img {
    width: 245px;
    height: 73px;

  }
  .footer-center ul li{
    font-size: 14px;
  }
  .footer-center p{
    font-size: 14px;
  }
  .footer-first p{
    font-size: 14px;
  }
  .footer-center h3{
    font-size: 18px;
  }
  .footer-center h2{
    font-size: 18px;
  }
}
@media (min-width: 767px) and (max-width: 1200px) {
  .footer-first img {
    width: 220px;
    height: 80px;
  }
}
@media (min-width: 250px) and (max-width: 700px) {
  .footer-center {
    text-align: start;
    padding-left: 15px;
  }
  .footer-center ul {
    padding: 0px;
  }
  .footer-first p {
    font-size: 16px;
    line-height: 30px;
    padding-right: 20px;
  }
  .footer-first img {
    width: 270px;
    height: 73px;
  }
  .branches_footer_social_media-icon svg {
    font-size: 25px;
    width: 35px;
    height: 35px;
  }
  .footer-center h3 {
    font-size: 20px;
    line-height: 43px;
  }
  .footer-center ul li {
    font-size: 16px;
    line-height: 32px;
  }
  .footer-center h2 {
    font-size: 20px;
    line-height: 40px;
  }
  .footer-center p {
    text-align: justify;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
  }
  .footer-first {
    padding-left: 18px;
  }
}
