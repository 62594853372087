.whitegloveOptimazation{
    padding: 5% 0% 0% 0%;
  }
  
 .whitegloveOptimazation .service-optimazation-section {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
  }
 .whitegloveOptimazation .service-optimazation-heading h2 {
    font-weight: 700;
    text-align: center;
    font-size: 38px;
  }
 .whitegloveOptimazation  .service-optimazation-content {
    padding: 0px 20px;
    text-align: center;
    /* flex-wrap: wrap; */
  }
 .whitegloveOptimazation .service-optimazation-content img {
    max-width:100%;
  }
 .whitegloveOptimazation .service-optimazation-content h6 {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
  }
 .whitegloveOptimazation .service-optimazation-content p {
    font-size: 16px;
    /* font-weight: 500; */
    color: #707070;
    padding: 10px;
    text-align: justify;
  }
  /* @media (min-width: 768px) and (max-width: 1100px) {
    .service-optimazation-content h6 {
      min-height: 120px;
    }
  } */
  
  @media only screen and (max-width: 900px) {
   .whitegloveOptimazation  .service-optimazation-content h6 {
      font-size: 25px;
      padding: 0px 10px;
      /* min-height: 100px; */
    }
   .whitegloveOptimazation  .service-optimazation-content p {
      font-size: 14px;
      font-weight: 500;
      color: #707070;
      padding: 5px;
      text-align: center;
    }
   .whitegloveOptimazation .service-optimazation-content img {
      height: 200px;
    }
  }
  @media only screen and (max-width: 550px) {
   .whitegloveOptimazation   .service-optimazation-heading h2 {
      font-weight: 700;
      text-align: center;
      font-size: 25px;
      /* text-align: center; */
      /* justify-content: center; */
    }
   .whitegloveOptimazation   .service-optimazation-content h6 {
      font-size: 25px;
    }
   .whitegloveOptimazation  .service-optimazation-content p {
      font-size: 15px;
    }
  }
  @media only screen and (max-width: 1440px) {
   .whitegloveOptimazation  .service-optimazation-content img {
      width: 100%;
    }
   .whitegloveOptimazation   .service-optimazation-content h6 {
      font-size: 20px;
    }
  }
  @media only screen and (max-width: 1025px) {
   .whitegloveOptimazation  .service-optimazation-content img {
      width: 205px;
      height: 130px;
    }
   .whitegloveOptimazation  .service-optimazation-content h6 {
      font-size: 20px;
    }
  }
  

  @media (min-width: 600px) and (max-width: 1024px){
    .whitegloveOptimazation .service-optimazation-heading h2 {
      font-size: 28px;
    }
    .branches-middle-service-right-section h1{
      font-size: 28px !important;
    }
    .branches-middle-service-right-section p{
      font-size: 16px;
    }
    .branches-middle-service-right-section h1{
      font-size: 28px !important;
    }
  }