.DropShipBanner {
    position: relative;
  }
  .DropShipBanner img{
    width: 100%;
    /* height: 80vh; */
  }
  .DropShipBanner_content{
    position: absolute;
    top: 32%;
    left: 3%;
  }
  .DropShipBanner  .DropShipBanner_content h1 {
    font-weight: 700;
    color: #333;
    font-size: 52px;
    font-family: Visby CF,Arial,sans-serif;
  }
  .DropShipBanner  .DropShipBanner_content p {
    color: #333;
    font-size: 20px;
    font-family: Visby CF,Arial,sans-serif;
  }
  .DropShipBanner  .DropShipBanner_content {
    width:45%
  }

  @media (min-width:600px) and (max-width:1023px){
    .DropShipBanner  .DropShipBanner_content h1 {
      font-size: 28px;
    }
    .DropShipBanner .DropShipBanner_content p{
      font-size: 16px;
    }
  }
  
  @media (min-width:1024px) and (max-width:1440px){

    .DropShipBanner img{
      /* height: 65vh; */
      width: 100%;
    }
    .DropShipBanner_content{
      position: absolute;
      top: 28%;
      left: 3%;
    }
    .DropShipBanner  .DropShipBanner_content h1 {
      font-size: 34px;
    }
    .DropShipBanner .DropShipBanner_content p{
      font-size: 18px;
    }
  }

  @media only screen and (max-width: 600px){
    .DropShipBanner  .DropShipBanner_content h1 {
      font-size: 28px;
    }
    .DropShipBanner img{
      height: 30vh;
    }
    .DropShipBanner  .DropShipBanner_content{
      width: 100%;
      position: unset;
      margin-top: 10%;
      padding: 0px 20px;
    }
    .DropShipBanner .DropShipBanner_content p{
      font-size: 16px;
    }
  }