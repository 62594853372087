.branches-career-job-main{
    padding: 20px 0px;
}
.branches-career-job-heading{
    display: flex;
    align-items: center;
    justify-content: center;
}
.branches-career-job-heading h1 {
    font-size: 32px;
    font-weight: 700;
    border-bottom: 2px solid black;
}
.barnches-career-job-talent-section{
/* width: 400px; */
background-color: #fff;
    border: 1px solid #f7a643;
    border-radius: 16px;
    justify-content: space-between;
    align-items: flex-end;
    max-width: 103.3rem;
    margin-top: 1.6rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 4rem;
    overflow: hidden;
    box-shadow: 0 26px 23px rgba(0, 72, 116, .07);
    padding: 40px 20px;
    width: 800px;
}
.barnches-career-job-talent-section{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.barnches-career-job-talent-section-left p {
color: #f7941e;;
font-weight: 600;
}
.barnches-career-job-talent-section-left h2{
    font-size: 25px;
    font-weight: 700;
}
.barnches-career-job-location-section select:focus-visible{
    outline: none;
}
.barnches-career-job-location-section select{
border:none;
-webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    font-size: 22px;
}
.location-selected option{
    width:200px
}
/* select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  } */
.barnches-career-job-location-section{
/* display: flex; */
/* justify-content: center; */
width:800px;
border-radius: 10px;
   border: 1px solid #f7a643;
    background-color: white;
    box-shadow: 0 10px 0px 0 hsla(210, 2%, 49%, 0.04);
}
.barnches-career-job-location-selected {
    padding: 20px 10px;
    background-color: #F0F0F0;
    border-radius: 10px;
    font-size: 22px;
    font-weight: 500;

}
.barnches-career-job-location-selected-isral h6{
    padding: 10px 20px;
    font-size: 22px;
    font-weight: 400;
    color:#777575;
    cursor: pointer;
}

@media only screen and (max-width:800px){
    .barnches-career-job-talent-section {
        width: 100%;
    }
    .branches-career-job-heading h1 {
        font-size: 25px;
    }
}