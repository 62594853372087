.branches_navbar_main img {
  width: 100%;
  cursor: pointer;
}

.branches_navbar_main {
  padding: 10px 2%;
}

.navbar_middle_lists li a{
  list-style: none;
  text-decoration: none;
  color: #333;
}
.branches_navbar_main ul li {
  list-style: none;
  text-decoration: none;
  padding: 5px 15px;
  color: #333;
  font-size: 16px;
  cursor: pointer;
  margin: 0px 10px;
  font-weight: 600;
}
.branches_navbar_main ul li:hover{
  color: #000;
}

.mt-button-shadow {
  z-index: 0;
  box-shadow: none;
  opacity: 0.9;
  filter: blur(6px);
  background-color: var(--bg-color);
  border-radius: 8px;
  width: 69%;
  height: 6px;
  transition: all 0.25s;
  position: absolute;
  top: auto;
  bottom: 1px;
  left: auto;
  right: auto;
}
.company-dropdown {
  position: relative;
}
.company-box {
  position: absolute;
  top: 30px;
  left: 0px;
  z-index: 99999;
  padding: 5px 10px;
  /* border: 1px solid black; */
  align-items: center;
  justify-content: start;
  box-shadow: 0px 0px 10px 2px rgb(202, 204, 206);
  /* background-color: rgb(202, 204, 206); */
  background-color: white;
  border-radius: 7px;
  width: 200px;
  border-top: 2px solid #f7941e;
  /* height: 200px; */
}
.company-box ul {
  padding: 0px;
}
.company-box ul li a{
  list-style: none;
  text-decoration: none;
  color: #333;
}
.company-box ul li:hover {
  color: #f7941e;
}
.moblie-navbar-box {
  display: none;
}
@media (min-width: 600px) and (max-width: 1024px){
  .branches_navbar_main img{
    width: 150px;
    height: 40px;
  }
}
@media (min-width: 1024px) and (max-width: 1440px) {
  .branches_navbar_main ul{
    /* margin-left: 30px; */
  }
  .branches_navbar_main img{
    width: 229px;
    height: 49px;
  }
  .navbar_middle_lists{
    margin-left: 50px !important;
  }
}
@media only screen and (max-width: 1300px) {
  .branches_navbar_main ul li {
    padding: 5px 10px;
    margin: 0px 5px;
    font-size: 16px;
  }
  .btn-box {
    padding: 5px 15px;
  }
}
@media only screen and (max-width: 1000px) {
  .btn-box {
    /* padding: 5px 10px; */
    font-size: 11px;
  }
  .branches_navbar_main ul li {
    font-size: 13px;
  }
  .branches_navbar_main img {
    height: 37px;
    width: 100%;
  }
}
@media only screen and (max-width: 768px) {
  .branches_navbar_main {
    display: none;
  }
  .moblie-navbar-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    height: 70px;
  }
  .mobile-log img {
    width: 200px;
    height: 40px;
  }
  .mobile-icons svg {
    font-size: 30px;
    position: relative;
  }
  .mobile-navbar-content {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100% !important;
    border: 1px solid #f7941e;
    top: 0px;
    left: 0px;
    background-color: #f7941e;
  }
  .mobile-navbar-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    padding: 20px;
  }
  .mobile-navbar-heading img {
    width: 190px;
    height: 70px;
  }
  .mobile-navbar-heading svg {
    font-size: 30px;
    color: white;
  }
  .mobile-navbar-sidebar ul {
    list-style: none;
  }
  .mobile-navbar-sidebar ul li {
    font-size: 20px;
    margin: 10px 0px;
    font-weight: 600;
    color: white;
  }
  .mobile-navbar-sidebar ul li a {
    font-size: 20px;
    margin: 10px 0px;
    font-weight: 600;
    color: white;
    text-decoration: none;
  }
  .mobile-navbar-line-box {
    border: 1px solid white;
  }
}

@media only screen and (max-width: 1400px) {
  .branches_navbar_main ul li {
    font-size: 16px;
  }
}
