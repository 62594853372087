
.LastMileServiceCard .branches-middle-main_first_section{
    margin-bottom: 40px;
  }
  .LastMileServiceCard  .branches-middle-section-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .LastMileServiceCard  .branches-middle-section-2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .LastMileServiceCard  .branches-middle-section-3 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .LastMileServiceCard  .branches-middle-section-4 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .LastMileServiceCard  .branches-middle-image {
    width: 100%;
  }
 
  .LastMileServiceCard   .branches-middle-service-right {
    /* border-left: 2px solid rgb(0, 0, 0); */
    /* padding-left: 10px; */
    margin-left: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .LastMileServiceCard   .branches-miidle-service-right-image {
    border-image: linear-gradient(#1ef5b9, #55c3ff 80%, #55c3ff);
    width: 2px;
  }
  .LastMileServiceCard .branches-middle-service-right-section h1 {
    font-size: 38px;
    font-weight: 700;
  }
  .LastMileServiceCard .branches-middle-service-right-section p {
    font-size: 18px;
    font-weight: 400;
  }
  @media only screen and (max-width: 990px) {
    .LastMileServiceCard   .branches-middle-service-right {
      margin-left: 0px;
    }
  }
  @media only screen and (max-width: 800px) {
    .LastMileServiceCard   .branches-middle-service-right {
      margin-left: 0px;
      text-align: center;
    }
    .LastMileServiceCard   .branches-middle-section-1 {
      flex-direction: column-reverse;
    }
   
    .LastMileServiceCard .branches-middle-service-right-section h1 {
      font-size: 25px;
    }
    
    .LastMileServiceCard .branches-middle-service-right-section h1 {
      font-size: 28px;
      font-weight: 700;
      text-align: left;
    }
    .LastMileServiceCard .branches-middle-service-right-section p {
      font-size: 15px;
      font-weight: 400;
      text-align: justify;
    }
    .LastMileServiceCard   .branches-middle-image {
      width: 95%;
    }
  }
  
  @media only screen and (max-width: 425px) {
    .LastMileServiceCard   .branches-middle-image img {
     width: 100%;
    }
  }
  