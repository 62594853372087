.OurDifference .DefrencesBanner {
  background-image: url("../.././../public//serviceImages/our_difference_banner.png");
  padding: 15% 3%;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}


.OurDifference .DefrencesBanner .banner_content h1{
    font-size:60px;
    font-weight: bold;
    color:#333 !important;
}
.OurDifference .DefrencesBanner .banner_content p{
    font-size:22px;
    font-weight: 500;
    color:white !important;
}
.OurDifference .DefrencesBanner .banner_content {
    width:50%
}
.OurDifference .service_card_section_one {
  padding: 10% 2% 3% 2%
}

.OurDifference  .service_card_section_one .image img{
  max-width: 100%;
}
.service_card_section_one h2 span{
  border-bottom:8px solid #f7a643
}
.service_card_section_one h2{
  text-align: left;
  font-weight: bold;
  font-size: 40px;
  padding-bottom: 5%;
}
.OurDifference .service_card_section_one  .image{
  border-radius: 15px;
}
.OurDifference .service_card_section_one .card_content  h3{
  font-weight: bold;
  font-size: 35px;
  
}

.OurDifference .service_card_section_one .card_content  p{
  font-size:18px;
  text-align: justify;
line-height: 30px;
}


/* service_card_section_two --------------------------------- */
.service_card_section_two {
  padding:5% 2%
}
.service_card_section_two h2 span{
  border-bottom:8px solid #f7a643
}
.service_card_section_two h2{
  text-align: left;
  font-weight: bold;
  font-size: 40px;
  padding-bottom: 3%;
}
.service_card_section_two .card {
  padding:5%;
  box-shadow: 0px 0px 10px 0px whitesmoke;
  border:none;
  background-color: black;
  height:100%;
  border-radius: 15px;
  cursor: pointer;
  transition: 0.5s;
}
.service_card_section_two .card:hover{
  background-color: darkorange;
}
.service_card_section_two .card:hover .image{
  background-color: white;
}
.service_card_section_two .card .image {
  width:70px;
  height:70px;
  border-radius: 50%;
  padding:10px
}

.service_card_section_two .card .image img{
width:100%
}

.service_card_section_two .card h3{
  color:white !important;
  margin-top:20px

}
.service_card_section_two .card  p{
  color:white !important;
  font-size:18px;
  margin:0px
}


@media (min-width: 767px) and (max-width: 1024px) {
  .OurDifference .DefrencesBanner .banner_content h1{
    font-size: 28px;
  }
  .service_card_section_one h2{
    font-size: 24px;
  }
  .OurDifference .service_card_section_one .card_content h3{
    font-size: 24px;
  }
  .service_card_section_two h2{
    font-size: 28px !important;
  }
}
@media only screen and (max-width: 475px){
  .OurDifference .DefrencesBanner{
    background-size: cover;

  }
  .OurDifference .DefrencesBanner .banner_content h1{
    font-size: 28px;
  }
  .OurDifference .DefrencesBanner .banner_content{
    width: 100%;
  }
  .service_card_section_one h2{
    font-size: 28px;
  }
  .service_card_section_one h2 span{
    border-bottom:4px solid #f7a643
  }
  .OurDifference .service_card_section_one .card_content h3{
    font-size: 28px;
  }
  .OurDifference .service_card_section_one .card_content p{
    font-size: 16px;
    line-height: 25px;
  }
  .service_card_section_two h2{
    font-size: 28px;
  }
  .service_card_section_two h2 span {
    border-bottom: 4px solid #f7a643;
  }
  .service_card_section_two .card p{
    font-size: 16px;
  }
}
