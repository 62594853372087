.EcommersBanner {
  position: relative;
}
.EcommersBanner img {
  /* height: 80vh; */
  width: 100%;
}
.EcommersBanner .EcommersBanner_content {
  position: absolute;
  top: 35%;
  left: 3%;
  width: 50%;
}
.EcommersBanner .EcommersBanner_content h1 {
  font-weight: 700;
  color: #333;
  font-size: 52px;
  font-family: Visby CF, Arial, sans-serif;
}
.EcommersBanner .EcommersBanner_content p {
  font-size: 20px;
  text-align: justify;
  color: #333;
  font-family: Visby CF, Arial, sans-serif;
}

@media (min-width: 600px) and (max-width: 1024px) {
  .EcommersBanner img {
    /* height: 65vh; */
    width: 100%;
  }
  .EcommersBanner .EcommersBanner_content {
    top: 28%;
    left: 3%;
  }
  .EcommersBanner .EcommersBanner_content h1 {
    font-size: 28px !important;
  }
  .EcommersBanner .EcommersBanner_content p{
    font-size: 18px !important;
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .EcommersBanner img {
    /* height: 65vh; */
    width: 100%;
  }
  .EcommersBanner .EcommersBanner_content {
    top: 28%;
    left: 3%;
  }
  .EcommersBanner .EcommersBanner_content h1 {
    font-size: 42px;
  }
  .EcommersBanner .EcommersBanner_content p{
    font-size: 18px;
  }
}

@media (min-width: 280px) and (max-width: 600px) {
  .EcommersBanner img {
    height: 30vh;
  }
  .EcommersBanner .EcommersBanner_content {
    position: unset;
    margin-top: 10%;
    width: 95%;
    padding: 0px 20px;
  }
  .EcommersBanner .EcommersBanner_content h1 {
    font-size: 28px;
  }
  .EcommersBanner .EcommersBanner_content p{
    font-size: 16px;
  }
}