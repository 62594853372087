.ValueBanner {
    background: linear-gradient(rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0)), url("../.././../public/new_banners_images/our_values_banner.png");
  padding: 16% 4%;
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.ValueBanner .banner_content h1{
    font-weight: 700;
    font-size: 52px;
    font-family: Visby CF,Arial,sans-serif;
    color:#333
}
.ValueBanner .banner_content  p{
    font-size:22px;
    font-weight: 500;
    color:#333
}
.ValueBanner  .banner_content {
    width:50%
}

@media only screen and (max-width: 475px){
    .ValueBanner .banner_content h1{
        font-size: 28px;
    }
}

@media (min-width: 600px) and (max-width: 1024px){
    .ValueBanner .banner_content h1{
        font-size: 28px;
    }  
}