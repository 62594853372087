.branches_our_value_main {
  /* background-color: #f5f0eb; */
  padding: 5% 0;
}
.branches_our_value_box {
  width: 100%;
  height: 100%;
  padding: 1.5em;
  background: linear-gradient(#ffffff 50%, #f6941e 50%);
  background-size: 100% 200%;
  background-position: 0 2.5%;
  border-radius: 5px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: 0.8s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}
.branches_our_value_box:hover{
  background-position: 0 100%;

}
.branches_our_value_box:hover h4{
  color:white !important;
}
.branches_our_value_box:hover p{
  color:white;
}

.branches_our_value_box h4 {
  color: var(--black);
  font-weight: 600;
  margin: 20px 0 15px 0;
  text-align: center;
}

.branches_our_value_box p {
  text-align: justify;
  padding: 0px ;
  font-size:18px;
}
.branches_our_value_main h2 {
  font-size: 32px;
  color: var(--black);
  font-weight: 700;
  text-align: center;
}

.branches_our_value_main h6 {
  font-size: 20px;
  color: rgb(32, 32, 32);
  text-align: center;
  font-weight: 400;
  padding: 0px 10%;
    line-height: 30px;
}

.branches_our_value_image_main {
  width: 80px;
  height: 80px;
  background-color: #fddfbc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

@media (max-width: 1400px) {
  .branches_our_value_box {
    height: 410px;
  }
}

@media (min-width: 1020px) and (max-width: 1200px) {
  .branches_our_value_box h4 {
    font-size: 18px;
  }
}

@media (min-width: 767px) and (max-width: 1024px) {
  .branches_our_value_box h4 {
    font-size: 16px;
  }
  .branches_our_value_main h6 {
    font-size: 18px;
    font-weight: 400;
  }
  .branches_our_value_main h2 {
    font-size: 25px;
  }
  .branches_our_value_box p {
    font-size: 14px;
    padding: 0px 0px;
  }
}

@media only screen and (max-width: 767px) {
  .branches_our_value_main h2 {
    font-size: 25px;
  }
  .branches_our_value_main h6 {
    font-size: 16px;
    padding: 0px 20px;
    font-weight: 400;
  }
  .branches_our_value_box h4 {
    font-size: 25px;
  }
}
