.branches_middle_section_first_main {
  /* background-color: #f5f0eb; */
  /* padding: 3% 0%; */
}

.branches_middle_section_first_main img {
  max-width:100%;
  display: inline;
}
.branches_middle_section_first_main h2 {
  font-size: 36px;
  font-weight: 700;
  color: #333;
}

.branches_middle_section_first_main p {
  font-size: 20px;
  text-align: justify;
}

/* ////////// second section /////////// */

.branches_middle_section_second_main {
  background-color: #ffffff;
  padding: 0% 0% 3% 0%;
}

.branches_middle_section_second_main img {
  max-width:100%;
  display: inline;
}
.branches_middle_section_second_main h2 {
  font-size: 36px;
  font-weight: 700;
  color: #333;
}

.branches_middle_section_second_main p {
  font-size: 20px;
  text-align: justify;
}

/* ///////////// third section//////// */
.branches_middle_section_third_main {
  /* background-color: #f5f0eb; */
  padding: 3% 0%;
}

.branches_middle_section_third_main img {
  max-width:100%;
  display: inline;
}
.branches_middle_section_third_main h2 {
  font-size: 36px;
  font-weight: 700;
  color: #333;
}

.branches_middle_section_third_main p {
  font-size: 20px;
  text-align: justify;
}

/* //// fourth section ////////// */

.branches_middle_section_forth_main {
  background-color: #ffffff;
  padding: 3% 0%;
}

.branches_middle_section_forth_main img {
  max-width:100%;
  display: inline;
}
.branches_middle_section_forth_main h2 {
  font-size: 36px;
  font-weight: 700;
  color: #333;
}

.branches_middle_section_forth_main p {
  font-size: 20px;
  text-align: justify;
}
@media only screen and (max-width: 990px) {
  .branches_middle_section_first_main p {
    font-size: 16px;
  }
  .branches_middle_section_first_main h2 {
    font-size: 25px;
  }
  .branches_middle_section_first_main {
    padding: 10px 0px;
  }
  /* ////////// second section /////////// */

  .branches_middle_section_second_main {
    background-color: #ffffff;
    padding: 10px 0px;
  }

  .branches_middle_section_second_main h2 {
    font-size: 25px;
  }

  .branches_middle_section_second_main p {
    font-size: 16px;
  }
  /* ///////////// third section//////// */
  .branches_middle_section_third_main {
    background-color: #f5f0eb;
    padding: 10px 0px;
  }

  .branches_middle_section_third_main h2 {
    font-size: 25px;
  }

  .branches_middle_section_third_main p {
    font-size: 16px;
  }

  /* //// fourth section ////////// */

  .branches_middle_section_forth_main {
    background-color: #ffffff;
    padding: 10px 0px;
  }

  .branches_middle_section_forth_main h2 {
    font-size: 25px;
    font-weight: 700;
  }
  .branches_middle_section_forth_main p {
    font-size: 16px;
  }
}

@media (min-width: 600px) and (max-width: 1024px){
  .branches_middle_section_first_main h2{
    font-size: 24px;
  }
  .branches_middle_section_first_main p{
    font-size: 16px;
  }
  .branches_middle_section_second_main h2{
    font-size: 24px;
  }
  .branches_middle_section_second_main p{
    font-size: 16px;
  }
  .branches_middle_section_third_main h2{
    font-size: 24px;
  }
  .branches_middle_section_third_main p{
    font-size: 16px;
  }
  .branches_middle_section_forth_main h2{
    font-size: 24px;
  }
  .branches_middle_section_forth_main p{
    font-size: 16px;
  }
  .branches_blog_main h2{
    font-size: 28px !important;
  }
  .branches_blog_main h6{
    font-size: 16px !important;
  }
  .branches_quotes_last_section_main p{
    font-size: 18px !important;
  }
}
@media only screen and (max-width: 767px) {
  .branches_middle_section_first_main p {
    font-size: 14px;
  }
  .branches_middle_section_first_main h2 {
    font-size: 25px;
  }
  .branches_middle_section_first_main {
    padding: 20px 20px;
  }
  /* ////////// second section /////////// */

  .branches_middle_section_second_main {
    background-color: #ffffff;
    padding: 20px 20px;
  }

  .branches_middle_section_second_main h2 {
    font-size: 25px;
  }

  .branches_middle_section_second_main p {
    font-size: 14px;
  }
  /* ///////////// third section//////// */
  .branches_middle_section_third_main {
    background-color: #f5f0eb;
    padding: 20px 20px;
  }

  .branches_middle_section_third_main h2 {
    font-size: 25px;
  }

  .branches_middle_section_third_main p {
    font-size: 14px;
  }

  /* //// fourth section ////////// */

  .branches_middle_section_forth_main {
    background-color: #ffffff;
    padding: 20px 20px;
  }

  .branches_middle_section_forth_main h2 {
    font-size: 25px;
    font-weight: 700;
  }
  .branches_middle_section_forth_main p {
    font-size: 14px;
  }
  .branches_middle_section_second_direction {
    flex-direction: column-reverse;
  }
  .branches_middle_section_forth_direction {
    flex-direction: column-reverse;
  }
}

@media only screen and (min-width: 1222px) and (max-width: 1066) {
  .branches_middle_section_second_main {
    padding: 10px 0px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 475px) {
    .branches_middle_section_third_main img {
      width: 100%;
    }
    .branches_middle_section_second_main img {
        width: 100%;
    }
    .branches_middle_section_first_main img {
        width: 100%;
    }
    .branches_middle_section_third_main {
       padding: 12% 3%;
      }
      .branches_middle_section_second_main {
         padding: 12% 3%;
      }
      .branches_middle_section_first_main {
         padding: 12% 3%;
      }
  }

  @media only screen and (max-width: 767px){
 
    .branches_middle_section_second_main{
      padding-top: 20px !important;
    }
    .branches-middle-main_first_section-row{
      --bs-gutter-y:30px !important;
    }
  }