

.branches_quotes_last_section{
    /* background-color: #f5f5f7; */
    padding-bottom: 40px;
}
.branches_quotes_last_section_main{
    background-color: #ffffff;
    border-radius: 20px;
}

.branches_quotes_last_section_main img{
    width: 100%;
}
.branches_quotes_last_section_main h2{
    font-size: 28px;
    font-weight: 700;
}

.branches_quotes_last_section_main p{
    font-size: 20px;
    text-align: justify;
}


@media (min-width:768px) and (max-width:900){
    .branches_quotes_last_section_main h2{
        font-size: 25px;
    }
}
@media only screen and (max-width:767px){
    .branches_quotes_last_section_main h2{
        font-size: 25px;
    }
    .branches_quotes_last_section_main p{
        font-size: 16px;
    }
    .branches_quotes_last_section_main{
        padding: 20px;
    }
}