.branches-service-bottom-main {
  padding: 5% 0%;
}

.branches-service-bottom-section {
  z-index: 1;
  border-radius: 16px;
  padding:90px 0px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(85, 195, 255, 0.11);
}

.branches-service-bottom-section p {
  font-size: 18px;
  font-weight: 400;
  text-align: justify;
}

.branches-service-bottom-section img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}
.branches-service-bottom-content {
  position: relative;
  top: 0px;
  left: 20px;
  z-index: 1;
  width: 400px;
}
.branches-service-bottom-content h4 {
  font-size: 38px;
  font-weight: 700;
  color: #333;
}
@media (min-width: 600px) and (max-width: 900px) {
  .branches-service-bottom-section {
    padding-left: 3rem;
  }
}

@media (min-width: 1025px) and (max-width:1440px){
  .branches-service-bottom-content h4{
    font-size: 26px;
  }
  .branches-service-bottom-section p{
    font-size: 16px;
  }
  .branches-about-bottom-content h3{
    font-size: 26px;
  }
}
@media (min-width: 600px) and (max-width:1024px){
  .branches-service-bottom-content h4{
    font-size: 28px;
  }
  .branches-service-bottom-section p{
    font-size: 16px;
  }
  .branches-service-bottom-section {
    padding:30px 0px;
  }
  .branches-service-bottom-section img{
    object-fit: contain;
  }
}

@media (min-width:1025px) and (max-width:1440px){

  .branches-service-bottom-section {
    padding:100px 0px;
  }
  .branches-service-bottom-section img{
    object-fit: cover;
  }
}
@media only screen and (max-width: 600px) {
  .branches-service-bottom-content {
    left: 0px;
    width: 100%;
  }
  .branches-service-bottom-section {
padding: 40px 0px;

  }
  .branches-service-bottom-content h3 {
    font-size: 22px;
  }
  .branches-service-bottom-main {
    padding: 16px 0px 0px 0px;
  }
  .branches-service-bottom-content h4{
    font-size: 26px;
  }
  .branches-service-bottom-section p{
 
    font-size: 16px;
  }
  .branches-middle-service-right-section{
    margin: 0px;
  }
}
