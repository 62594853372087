.WhitelayoutBanner {

    position: relative;
  }
  .WhitelayoutBanner img{
    /* height: 80vh; */
    width: 100%;
  }
  .WhitelayoutBanner_content {
    width: 45%;
    position: absolute;     
    top: 35%;
    left: 3%;
  }
  .WhitelayoutBanner_content h1 {
    font-weight: 700;
    color: #333;
    font-size: 52px;
    margin: 0px;
    font-family: Visby CF, Arial, sans-serif;
    letter-spacing: -.015em;
  }
  .WhitelayoutBanner_content p{
    font-size:20px;
    color:#333;
    text-align:justify;
    font-family: Visby CF, Arial, sans-serif;
    letter-spacing: -.015em;
  }
  @media (min-width:768px) and (max-width:1024px){
    .branches_service_banner_content h1 {
      font-size: 24px !important;
    }
  
    .WhitelayoutBanner_content {
      width: 43%;
      top:6% !important;
    }
    .WhitelayoutBanner_content h1 {
      font-size: 28px !important;
    }
    .WhitelayoutBanner_content p{
      font-size: 16px !important;
    }
  }
  @media (min-width:1024px) and (max-width:1440px){
    .branches_service_banner_content h1 {
      font-size: 30px;
    }
    .WhitelayoutBanner img{
      /* height: 75vh; */
      width: 100%;
    }
    .WhitelayoutBanner_content {
      width: 40%;
      top: 28%;
    }
    .WhitelayoutBanner_content h1 {
      font-size: 34px;
    }
    .WhitelayoutBanner_content p{
      font-size: 18px;
    }
  }

 
  @media only screen and (max-width: 990px) {
    .branches_service_banner_content h1 {
      font-weight: 700;
      color: var(--black);
      font-size: 25px;
    }
    .branches_service_banner_left-side h6 {
      font-size: 18px;
    }
   
  }
  @media only screen and (max-width: 600px) {
    .branches_service_banner_main {
      height: 200px;
    }
    .branches_service_banner_left-side h1 {
      font-weight: 700;
      color: var(--black);
      font-size: 25px;
    }
    .branches_service_banner_left-side h6 {
      font-size: 18px;
    }
    .branchesglobalnetworks_global_button {
      font-size: 14px;
    }
  }
  


  @media only screen and (max-width: 1200px) {
    .branches_service_banner_content h1 {
      font-size: 25px;
    }
  }
  
  @media only screen and (max-width: 450px) {
    .branchesglobalnetworks_global_button {
      font-size: 14px;
    }

    .WhitelayoutBanner_content h1 {
      font-size: 28px;
    }
    .WhitelayoutBanner_content p{
      font-size: 16px;
    }
    .WhitelayoutBanner_content{
      width: 95%;
      top: 2%;
      position: unset;
      margin-top: 30px;
    }
    .WhitelayoutBanner img{
      height: 30vh;
    }

  }
  @media only screen and (max-width: 450px) {
    .branches_service_banner_content {
      bottom: 50%;
    }
    .branches_service_banner_main img {
      height: 180px;
    }
  }
  