.LastMileBanner {
  position: relative;
}
.LastMileBanner img {
  height: 80vh;
  width: 100%;
}
.LastMileBanner .LastMileBanner_content {
  width: 45%;
  position: absolute;
  top: 35%;
  left: 3%;
}
.LastMileBanner .LastMileBanner_content h1 {
  font-weight: 700;
  color: #333;
  font-size: 52px;
  font-family: Visby CF, Arial, sans-serif;
}
.LastMileBanner .LastMileBanner_content p {
  font-size: 20px;
  text-align: justify;
  color: #333;
  font-family: Visby CF, Arial, sans-serif;
}

@media (min-width: 600px) and (max-width: 1024px){
  .LastMileBanner .LastMileBanner_content h1{
    font-size: 28px;
  }
  .LastMileBanner .LastMileBanner_content p{
    font-size: 16px;
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .LastMileBanner img {
    /* height: 65vh; */
    width: 100%;
  }
  .LastMileBanner .LastMileBanner_content {
    width: 45%;
    top: 28%;
    left: 3%;
  }
  .LastMileBanner .LastMileBanner_content h1 {
    font-weight: 700;
    font-size: 34px;
  }
  .LastMileBanner .LastMileBanner_content p{
    font-size: 18px;
  }
}

@media (min-width: 280px) and (max-width: 600px){
  .LastMileBanner .LastMileBanner_content h1 {
    font-size: 28px;
  }
  .LastMileBanner .LastMileBanner_content {
    width: 95%;
    position: unset;
    margin-top: 10%;
  }
  .LastMileBanner img{
    height: 50vh;
  }
  .LastMileBanner .LastMileBanner_content p{
    font-size: 16px;
  }
}
