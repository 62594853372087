
.branches-middle-main_first_section{
  margin-bottom: 40px;
}
.branches-middle-section-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.branches-middle-section-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.branches-middle-section-3 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.branches-middle-section-4 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.branches-middle-image {
  width: 100%;
}
.branches-middle-image img {
max-width: 100%;
}
.branches-middle-service-right {
  /* border-left: 2px solid rgb(0, 0, 0); */
  /* padding-left: 10px; */
  margin-left: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.branches-miidle-service-right-image {
  border-image: linear-gradient(#1ef5b9, #55c3ff 80%, #55c3ff);
  width: 2px;
}
.branches-middle-service-right-section {
  margin-left: 20px;
}
.branches-middle-service-right-section h1 {
  font-size: 38px;
  font-weight: 700;
}
.branches-middle-service-right-section p {
  font-size: 18px;
  font-weight: 400;
  text-align: justify;
}
@media only screen and (max-width: 990px) {
  .branches-middle-service-right {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 800px) {
  .branches-middle-service-right {
    margin-left: 0px;
    text-align: center;
  }
  .branches-middle-section-1 {
    flex-direction: column-reverse;
  }
  /* .branches-middle-section-3 {
    flex-direction: column-reverse;
  } */
  .branches-middle-service-right-section h1 {
    font-size: 25px;
  }
  /* .branches-middle-image img {
    height: 300px;
  } */
  .branches-middle-service-right-section h1 {
    font-size: 26px;
    font-weight: 700;
    text-align: left;
  }
  .branches-middle-service-right-section p {
    font-size: 15px;
    font-weight: 400;
    text-align: justify;
  }
  .branches-middle-image {
    width: 95%;
  }
}
/* 
@media only screen and (max-width: 992px) {
  .branches-middle-image {
    width: 80%;
  }
} */

/* @media only screen and (max-width: 992px) {
  .branches-middle-image img {
    height: 320px;
    width: 340px;
  }
}

@media only screen and (max-width: 375px) {
  .branches-middle-image img {
    height: 280px;
    width: 300px;
  }
} */


@media only screen and (max-width: 425px) {
  .branches-middle-image img {
   width: 100%;
  }
}
