.EcommerceOptimazation {
  padding: 5% 0%;
}

.EcommerceOptimazation .service-optimazation-section {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}
.EcommerceOptimazation .service-optimazation-heading h2 {
  font-weight: 700;
  text-align: center;
  font-size: 38px;
}
.EcommerceOptimazation .service-optimazation-content {
  padding: 0px 20px;
  text-align: center;
  /* flex-wrap: wrap; */
}
.EcommerceOptimazation .service-optimazation-content img {
  max-width: 100%;
}
.EcommerceOptimazation .service-optimazation-content h6 {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}
.EcommerceOptimazation .service-optimazation-content p {
  font-size: 16px;
  color: #707070;
  padding: 10px;
  text-align: justify;
}


@media only screen and (max-width: 900px) {
  .EcommerceOptimazation .service-optimazation-content h6 {
    font-size: 25px;
    padding: 0px 10px;
    /* min-height: 100px; */
  }
  .EcommerceOptimazation .service-optimazation-content p {
    font-size: 14px;
    font-weight: 500;
    color: #707070;
    padding: 5px;
    text-align: justify;
  }
  .EcommerceOptimazation .service-optimazation-content img {

  }
}
@media only screen and (max-width: 550px) {
  .EcommerceOptimazation .service-optimazation-heading h2 {
    font-weight: 700;
    text-align: center;
    font-size: 25px;
  }
  .EcommerceOptimazation .service-optimazation-content h6 {
    font-size: 25px;
  }
  .EcommerceOptimazation .service-optimazation-content p {
    font-size: 15px;
  }

}
@media only screen and (max-width: 1440px) {
  .whitegloveOptimazation .service-optimazation-content img {
    width: 100%;
  }
  .whitegloveOptimazation .service-optimazation-content h6 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 1025px) {
  .whitegloveOptimazation .service-optimazation-content img {
    width: 205px;
    height: 130px;
  }
  .whitegloveOptimazation .service-optimazation-content h6 {
    font-size: 20px;
  }
}
