.branches_blog_main {
    /* background-color: #f5f5f7; */
    padding: 5% 0;
  }
  
  .branches_blog_box {
    background-color: var(--white);
    border-radius: 10px;
    box-shadow: 0 5px 20px rgba(51,51,51,.09);
    padding: 0px 0px 20px 0px;
    height:100%;
  }
  .branches_blog_main a{
   text-decoration: none;
   list-style: none;
  }

  .branches_blog_box img{
    width: 100%;
    height:300px;
  }
  .branches_blog_box h4{
      color: var(--black);
      font-weight: 600;
      margin: 20px 0 15px 0;
      padding: 0px 20px;
      font-size: 22px;
  }
  
  .branches_blog_box p{
      text-align: justify;
      padding: 0px 20px;
      color: #313130;
      margin: 0px;
  }
  .branches_blog_main h2 {
    font-size: 38px;
    color: var(--black);
    font-weight: 700;
    text-align: center;
  }
  
  .branches_blog_main h6 {
      font-size: 20px;
      color: rgb(32, 32, 32);
      text-align: center;
      font-weight: 400;
  }
 
  .branches_blog_box button{
    background-color: var(--white);
    color: var(--bg-color);
    border: none;
    outline: none;
    padding: 8px 20px;
    margin-top: 10px;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  .branches_blog_box svg{
    color: var(--bg-color);
    font-size: 18px;
  }

  .branches_blog_date {
    color: #313130;
    font-size: 16px;
    font-weight: 500;
  }

  @media screen and (max-width:1250px){
    .branches_blog_box p {
      font-size:15px;
    }
  }

@media (min-width:768px) and (max-width:990px){
  .branches_blog_box h4 {
    font-size: 16px;
  }
  .branches_blog_box p{
    padding: 0px 15px;
    font-size: 14px;
  }
  .branches_blog_main h2 {
    font-size: 25px;
  }
  /* .branches_blog_box p {
    font-size: 14px;
  } */
}


  @media only screen and (max-width:767px){
    .branches_blog_main h2 {
      font-size: 25px;
    }
    .branches_blog_main h6{
      font-size: 16px;
    }
    .branches_blog_box h4{
      font-size: 22px;
    }
  }