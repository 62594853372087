.branches-career-our-latest-mani{
    padding: 20px 0px;
}
.branches-career-our-latest-heading{
    display: flex;
    align-items: center;
    justify-content: center;

}
.branches-career-our-latest-heading h1 {
    font-size: 32px;
    font-weight: 700;
    border-bottom: 2px solid black;
}
.branches-career-our-latest-section{
    padding: 40px 0px;
}
.branches-career-our-latest-section-card{
    background-color: #fff;
    border-radius: 24px;
    border-color: #eaaf66;
    border: 1px solid #eaaf66;
    width: 100%;
    height: 450px;
    padding: 1.6rem 1.6rem 2.2rem;
    transition: transform .35s, box-shadow .35s;
    box-shadow: 0 5px 20px rgba(51, 51, 51, .09);
}
.branches-career-our-latest-section-card img {
    height: 200px;
    width: 100%;
    border-radius: 10px;
}
.branches-career-our-latest-section-card p{
    padding: 5px;
    font-size: 18px;
    background-color: #e2ffea;
    /* width: 50px; */
    color: #eeab59;
    border-radius: 5px;
}
.branches-career-our-latest-section-card a {
    color:#f7941e;
}
@media only screen and (max-width:1150px){
    .branches-career-our-latest-section-card h4 {
        font-size: 18px;
    }
    }
@media only screen and (max-width:800px){
.branches-career-our-latest-section-card h4 {
    font-size: 16px;
}
.branches-career-our-latest-heading h1{
    font-size: 25px;
}
}