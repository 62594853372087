
.branch_topbar_main{
    background-color: var(--bg-color);
    color: var(--white);
}

.branch_topbar_main p{
    margin: 0px ;
    padding: 10px;
    font-size: 16px;
}
@media only screen and (max-width:768px){

.branch_topbar_main p{
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}
}


