
.branches_home_banner_main{
    position: relative;
}
.branches_home_banner_image img{
    width: 100%;
    height: 650px;
}

.branches_home_banner_image{
  display: block;
  /* height: 100vh; */
}
.branches_home_banner_image_mobile{
  display: none;
}

.branches_home_banner_content {
    position: absolute;
    top: 30%;
    left: 3%;
    width: 50%;
}

.branches_home_banner_content h1{
  font-weight: 700;
  color: #333;
  font-size: 52px;
  margin: 0px;
  font-family: Visby CF,Arial,sans-serif;
  letter-spacing: -.015em;
}

.branches_home_banner_content h6{
  font-size: 26px;
  color: #333;
  font-family: Visby CF,Arial,sans-serif;
}
@media only screen and (max-width:767px){
  .branches_home_banner_image img{
    width: 100%;
    height: 300px;
}
.branches_home_banner_content h1{
  font-weight: 700;
  color: var(--black);
  font-size: 22px;
}
.branches_home_banner_content h6{
  font-size: 16px;
}
.branches_home_banner_content{
  position: relative;
  top:30px;
  margin-bottom: 60px;
  width: 100%;
}
}

@media (min-width: 250px) and (max-width: 425px) {
  .branches_home_banner_image{
    display: none;
  }
  .branches_home_banner_image_mobile{
    display: block;
  }
}

@media (min-width: 600px) and (max-width: 1024px){
  .branches_home_banner_content h1{
    font-size: 32px;
  }
  .branches_home_banner_content h6{
    font-size: 20px;
  }
}

@media (min-width: 1024px) and (max-width: 1440px){
  .branches_home_banner_content h1{
    font-size: 45px;
  }
}