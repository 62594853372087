.contact-banner {
  /* background-image: url("/public/serviceImages/contactus.png");
  width: 100%;
  height: 80vh;
  background-position: cover;
  background-repeat: no-repeat; */
}

.contact-banner img{
  width: 100%;
}
.contact-banner h1 {
  font-weight: 700;
  font-size: 52px;
  font-family: Visby CF, Arial, sans-serif;
}
.contact-banner h1{
  width: 45%;
  position: absolute;
  top: 45%;
  left: 3%;
}
.contact-content {
  padding: 20px 0px;
  width: "100%";
  /* background-image: linear-gradient(to bottom, var(--bg-color), #f7a643); */
  /* height: auto; */
  align-items: center;
  justify-content: center;
  display: flex;
  /* height: 600px; */
  /* background-image:url("/public/AboutImages/contactus.jpg"); */
}
.contact-content-section {
  /* width: 600px; */
  margin-left: 20px;
  padding: 10% 0%;
}
.branches-form-input-field textarea.form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: #727070;
}
.branches-contact-left-side {
  background-image: linear-gradient(to bottom, var(--bg-color), #f7a643);
  border-radius: 10px;
  padding: 50px;
}
.branches-contact-left-side h1 {
  color: white !important;
}
.branches-contact-left-side p {
  color: white !important;
}
.branches-contact-left-side-section {
  display: flex;
  align-items: center;
  margin: 10px 0px;
}
.branches-contact-left-side-section svg {
  font-size: 18px;
  border-radius: 50px;
  border: 1px solid rgb(231, 229, 229);
  /* padding: 10px; */
  height: 40px;
  width: 40px;
  padding: 8px;
  color: white;
}
.branches-contact-left-side-section-address {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: left;
}
.branches-contact-left-side-section-address a{
  list-style: none;
  color: #fff;
  text-decoration: none;
}
.branches-contact-left-side-section-address a:hover{
  text-decoration: underline;
}
.branches-contact-left-side-section-address span {
  font-size: 18px;
  font-weight: 500;
  color: white;
}
.branches-contact-left-side-section-address p {
  margin-bottom: 0px;
  font-size: 16px;
  color: white;
  margin-left: 8px;
}

@media only screen and (max-width: 750px) {
  .branches-contact-left-side {
    height: auto;
  }
  .branches-contact-left-side-section {

    margin-top: 20px;
  }
  .contact-content-section {
    margin: 0px;
  }
  .branches-contact-left-side-section-address {
    margin-top: 10px;
    align-items: start;
  }
}
@media only screen and (max-width: 1024px){
  .contact-banner h1{
    font-size: 28px;
  }
}
@media only screen and (max-width: 475px){
  .contact-banner{
      background-size: contain;
      height: 45vh;
  }
  .contact-banner h1{
    font-size: 28px;
    top: 35%;
  }
  .branches-contact-left-side{
    padding: 15px;
  }
  .branches-contact-left-side-section svg{
    width: 30px;
    height: 30px;
  }
}
