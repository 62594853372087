.FulfillmentBanner {
  position: relative;
}

.FulfillmentBanner img {
  max-width: 100%;
  /* height: 80vh; */
}
.second_fullfilment img {
  width: 100% !important;
}
.FulfillmentBanner .FulfillmentBanner_content {
  width: 50%;
  position: absolute;
  top: 30%;
  left: 3%;
}
.FulfillmentBanner .FulfillmentBanner_content h1 {
  font-weight: 700;
  color: #333;
  font-size: 52px;
  font-family: Visby CF, Arial, sans-serif;
}
.FulfillmentBanner .FulfillmentBanner_content p {
  font-size: 20px;
  text-align: justify;
  color: #333;
  font-family: Visby CF, Arial, sans-serif;
}

@media (min-width: 600px) and (max-width: 1024px) {
  .FulfillmentBanner .FulfillmentBanner_content h1 {
    font-size: 28px;
  }
  .FulfillmentBanner .FulfillmentBanner_content p {
    font-size: 16px;
  }
}
@media (min-width: 1024px) and (max-width: 1440px) {
  .FulfillmentBanner .FulfillmentBanner_content h1 {
    font-size: 34px;
  }
  .FulfillmentBanner .FulfillmentBanner_content {
    top: 23%;
  }
  .FulfillmentBanner img {
    /* height: 65vh; */
    width: 100%;
  }
  .FulfillmentBanner .FulfillmentBanner_content p {
    font-size: 18px;
  }
}

@media (min-width: 280px) and (max-width: 600px) {
  .FulfillmentBanner .FulfillmentBanner_content h1 {
    font-size: 28px;
  }
  .FulfillmentBanner .FulfillmentBanner_content p {
    font-size: 16px;
  }
  .FulfillmentBanner .FulfillmentBanner_content {
    width: 95%;
    position: unset;
    margin-top: 10%;
    padding: 0px 15px;
  }
  .FulfillmentBanner img {
    width: 100%;
  }
}
